const CalloutHelpIcon = ({ ...props }) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.6648 13.7974C10.6648 13.8803 10.7319 13.9474 10.8148 13.9474H12.399C12.4818 13.9474 12.549 13.8803 12.549 13.7974V13.7784C12.5523 13.4536 12.5937 13.1802 12.6733 12.9581C12.7562 12.736 12.8804 12.5405 13.0462 12.3714C13.2152 12.2024 13.4306 12.04 13.6925 11.8842C14.004 11.6986 14.2741 11.4915 14.5028 11.2628C14.7315 11.0308 14.9089 10.7656 15.0348 10.4673C15.1641 10.1657 15.2287 9.82102 15.2287 9.43324C15.2287 8.85322 15.0845 8.35772 14.7962 7.94673C14.5078 7.53575 14.1101 7.22254 13.603 7.0071C13.0959 6.78835 12.5125 6.67898 11.853 6.67898C11.2531 6.67898 10.7045 6.78669 10.2074 7.00213C9.71023 7.21425 9.31084 7.53741 9.00923 7.97159C8.74736 8.3457 8.59293 8.80725 8.54593 9.35625C8.53395 9.49626 8.64777 9.61222 8.78829 9.61222H10.3193C10.4539 9.61222 10.5615 9.50504 10.5858 9.37274C10.6167 9.2042 10.6746 9.05698 10.7592 8.93111C10.8819 8.7455 11.0393 8.6063 11.2315 8.51349C11.4238 8.41738 11.6276 8.36932 11.843 8.36932C12.0651 8.36932 12.2673 8.41572 12.4496 8.50852C12.6352 8.60133 12.7827 8.7339 12.892 8.90625C13.0014 9.0786 13.0561 9.27912 13.0561 9.50781C13.0561 9.72325 13.008 9.9188 12.9119 10.0945C12.8158 10.2668 12.6866 10.4242 12.5241 10.5668C12.3617 10.7093 12.1778 10.8468 11.9723 10.9794C11.6972 11.1518 11.4619 11.344 11.2663 11.5561C11.0708 11.7682 10.9216 12.0466 10.8189 12.3913C10.7195 12.736 10.6681 13.1984 10.6648 13.7784V13.7974ZM10.799 16.7812C11.0343 17.0133 11.3177 17.1293 11.6491 17.1293C11.8613 17.1293 12.0568 17.0762 12.2358 16.9702C12.4148 16.8608 12.5589 16.7166 12.6683 16.5376C12.781 16.3554 12.839 16.1548 12.8423 15.9361C12.839 15.6113 12.718 15.3329 12.4794 15.1009C12.2408 14.8688 11.964 14.7528 11.6491 14.7528C11.3177 14.7528 11.0343 14.8688 10.799 15.1009C10.567 15.3329 10.4527 15.6113 10.456 15.9361C10.4527 16.2642 10.567 16.5459 10.799 16.7812Z"
			fill="#4B4BFF"
		/>
	</svg>
)

export default CalloutHelpIcon
